import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#EF8535',
        secondary: '#296DC0',
        info: '#6AADDB',
        success: '#39b54a',
        warning: '#ea9948',
        lightgrey: '#eee',
        bordergrey: '#E4E4E4',
        medgrey: '#999999',
        textgrey: '#808180',
        darkgrey: '#222',
        navy: '#121f3e',
        orange: '#EF8535',
        yellow: '#f4c041',
        green: '#2BB673',
        white: '#fff',
        teal: '#087A86',
        red: colors.red
      }
    }
  }
})
