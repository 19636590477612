import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Toasted from 'vue-toasted'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000
})

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
