import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const GradingView = () => import(/* webpackChunkName: "grading-page" */ '../views/GradingView.vue')
const ErrorView = () => import(/* webpackChunkName: "error-page" */ '../views/ErrorView.vue')

const routes = [
  {
    path: '/',
    name: 'Grading',
    component: GradingView
  },
  {
    path: '*',
    name: 'Error',
    component: ErrorView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (
    to.name === 'Grading' &&
    !to.query.token &&
    !to.query.stage
  ) {
    next({ name: 'Error' })
  } else {
    next()
  }
})

export default router
